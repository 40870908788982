
.text-xxl {
    font-size: 4em;
}
.text-xl {
    font-size: 3em;
}
.text-l {
    font-size: 1.3em;
}
.text-m {
    font-size: 1em;
}
.text-s {
    font-size: 0.8em;
}
.text-xs {
    font-size: 0.6em;
}
.text-xxs {
    font-size: 0.4em;
}
.text-weight-xxl {
    font-weight: bolder;
}
.text-weight-xl {
    font-weight: bold;
}
.text-weight-l {
    font-weight: 900;
}
.text-weight-lm {
    font-weight: 800;
}
.text-weight-m {
    font-weight: 700;
}
.text-weight-sm {
    font-weight: 600;
}
.text-weight-s {
    font-weight: 500;
}
.text-weight-xs {
    font-weight: 400;
}
.text-weight-xxs {
    font-weight: 300;
}
.text-style-italic {
    font-style: italic;
}
.text-style-normal {
    font-style: normal;
}
.text-center {
    text-align: center;
}