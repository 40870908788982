@keyframes grow {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes absoluteGrow {
  0% {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideExitLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideExitRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}
@keyframes slideUp {
  0% {
    transform: translate(-50%, 0%);
  }
  100% {
    transform: translate(-50%, -300%);
  }
}
@keyframes slideDown {
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 0%);
  }
}
@keyframes slideEntryDown {
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
@keyframes shootEffect {
  0% {
    transform: translate(0, 0) skew(-25deg);
  }
  45% {
    transform: translate(-110%, 0) skew(-25deg);
  }
  60% {
    transform: translate(110%, 0) skew(-25deg);
  }
  80% {
    transform: translate(70%, 0) skew(-25deg);
  }
  100% {
    transform: translate(100%, 0) skew(-25deg);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.5);
  }
  66% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
@media screen and (max-width: 800px) {
  .screen-container .container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    gap: 1%;
  }
  .screen-container .container > div:nth-child(2) {
    order: 3;
    flex-direction: column;
  }
  .screen-container .container > div:nth-child(2) .column {
    padding: 1rem;
  }
  .screen-container .container > div:nth-child(3) {
    order: 2;
  }
  .screen-container .container > div:nth-child(3) .timer-counter {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .how-to-title {
    font-size: 2rem;
  }
  .step-title {
    font-size: 1.6rem;
  }
  .step-description {
    font-size: 1rem;
  }
}/*# sourceMappingURL=media.css.map */