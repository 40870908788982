@import './mixins';
@import './variables';
@import './keyframes';
// @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: "Fira Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    transition: all 250ms;
    border: none;
}

html {
    scroll-behavior: smooth;
    scrollbar-width: 1px;
}

body {
    width: 100vw;
    min-height: 100vh;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 300;
}

header {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 998;

    &.header {
        nav {
            @include elastic-width($content-width);
            @include centered-by-margin();
            padding: inherit;
        }
    }

    &.desktop {
        padding: 5px 1.1%;

        nav {
            overflow: clip;
            @include flex-box(center, space-between, row, 10px);

            .menu-toggle {
                display: none;
            }

            .menu {
                .menu-wrapper {
                    @include flex-box(center, space-between, row, 40px);
                    width: max-content;

                    &>div:first-child {
                        @include flex-box(center, center, row, 0px);
                    }

                    &>div:last-child {
                        width: max-content;
                        @include flex-box(center, center, row, 10px);
                    }

                    .menu-item {
                        padding: 10px 20px;

                        &.active {
                            @include box-shadow(0px, 10px, 20px, 1px, $secondary-color, inset);
                        }
                    }
                }
            }
        }
    }

    &.mobile {
        @include box-border(bottom, 2px, solid, $fade-color);
        z-index: 999;

        nav {
            position: relative;

            .header-icon {
                @include flex-box(center, center);
                width: 100%;
                padding: 10px;
                height: 4rem;
                position: relative;

                img {
                    height: 1.6rem;
                }

                .menu-toggle {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translate(0, -50%);
                    padding: 1%;

                    svg {
                        height: 2rem;
                        width: auto;
                        cursor: pointer;
                        fill: $primary-color;
                    }
                }
            }

            .menu {
                width: 100%;
                height: calc(100vh - 2rem - 2px);
                backdrop-filter: blur(10px);
                position: absolute;
                top: calc(100% + 2px);
                left: 0;

                &.hidden {
                    display: none;
                }

                &.transient {
                    .menu-wrapper {
                        animation: slideExitLeft 250ms alternate;
                        transform: translateX(-100%);
                    }
                }

                &.visible {
                    .menu-wrapper {
                        animation: slideRight 250ms alternate;
                        transform: translateX(0);

                    }

                }

                .menu-wrapper {
                    @include elastic-width(500px);
                    height: 100%;
                    position: relative;

                    .menu-item {
                        margin: 0.3rem;
                        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
                    }

                    .selector {
                        @include flex-box(center, flex-start);
                        padding: 2%;
                        background-color: $primary-color;

                        svg {
                            height: 2rem;
                            width: 2rem;
                        }
                    }

                    .actions {
                        @include flex-box(center);
                        margin-top: 2rem;
                    }
                }
            }
        }
    }
}

main {
    @include elastic-width($content-width);
    @include centered-by-margin();
    padding: 1.1%;
    padding-bottom: 5rem;

    .map {
        @include flex-box(center, center);
        width: fit-content;
        font-size: 0.8rem;
        margin: 1rem 0;
        padding: 0.3rem 1rem;

        .pos {
            padding: 0 4%;
            white-space: nowrap;
            font-style: italic;
        }

        svg {
            width: 1rem;
            height: 1rem;
            fill: $primary-color;
        }
    }

    .container.guide-menu {
        .row {
            &.top {
                @include flex-box(center);
                height: calc(2rem + 2%);
                padding-left: 4.4rem;
                width: fit-content;

                .column {
                    flex: 1;
                    height: 1;
                    white-space: nowrap;
                    padding: 2% 4%;
                    padding-right: calc(4% + 2rem);
                    background-color: $primary-fade-color;
                    border-radius: 0 2rem 0 0;
                    position: relative;
                    color: $gray-color;
                    font-weight: 700;
                    font-size: 0.8rem;

                    &::before {
                        content: "";
                        height: 100%;
                        width: 1rem;
                        position: absolute;
                        background-color: $primary-fade-color;
                        top: 0;
                        left: -1rem;
                        z-index: -1;
                    }

                    &.active {
                        background-color: $primary-color;
                        color: $light-text;

                        &::before {
                            content: "";
                            background-color: $primary-color;
                            z-index: 1;
                        }
                    }
                }
            }

            &.bottom {
                width: 100%;
                height: 2rem;
                background-color: $primary-color;
                border-radius: 2rem 2rem 0 0;
                z-index: -2;
            }
        }
    }

    .guide-content {
        .how-to-container {
            @include elastic-width(1024px);
            padding: 20px;
            margin: auto;
            font-family: 'Arial', sans-serif;
        }

        .how-to-title {
            font-size: 2.5rem;
            text-align: center;
            color: #333;
            margin-bottom: 20px;
        }

        .how-to-intro {
            font-size: 1.2rem;
            text-align: center;
            color: #666;
            margin-bottom: 40px;
        }

        .steps-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            overflow: clip;
            padding: 1rem;
        }

        .step-card {
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            transform: translateX(0);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            animation: slideRight 250ms alternate;
        }

        .step-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
        }

        .step-title {
            font-size: 1.3rem;
            margin-bottom: 10px;
            color: $primary-color;
            width: fit-content;
            border-bottom: 2px solid $primary-color;
        }

        .step-description {
            font-size: 1.1rem;
            color: #555;
        }
    }
}

.theme-toggle {
    @include flex-box(center, flex-start, row, 10px);
    width: 7rem;
}

.page-banner {
    width: 100%;
    min-height: 30vh;
    padding-bottom: 4rem;
}

.table-stats {
    @include flex-box(center, space-between);
    width: 100%;

    div {
        flex: 1;
        @include flex-box(center, center, column);
    }
}

.task-container {

    .tasks {
        @include flex-box(baseline, flex-start, column, 2rem);
        @include elastic-width(500px);
        @include centered-by-margin();
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2rem), calc(100% - 2rem) 100%, 0 100%);
        padding: 2rem 1rem;


        .task {
            @include box-border(all, 2px, solid, $primary-color);
            width: 100%;
            height: max-content;
            position: relative;

            .action-box {
                @include flex-box(center, center);
                position: absolute;
                height: 100%;
                top: 0;
                right: 0;
                width: 2rem;
                background-color: $secondary-color;
                cursor: pointer;

                &:hover {
                    background-color: $primary-color;
                }
            }
            .text-box {
                padding: 2%;

                .rate {
                    @include flex-box(center, flex-start, row, 0.3rem);
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                    background-color: $gray-color;
                    color: $light-text;
                    padding: 0.3rem;
                    font-size: 0.6rem;
                    font-weight: 800;
                    font-style: italic;

                    &.earned {
                        background-color: green !important;

                        svg {
                            fill: goldenrod;
                        }
                    }
                }
            }
            .post {
                width: calc(100% - 2rem);
            }
        }
    }
    .text-box {
        position: relative;
    }
    .post {
        padding: 1rem;
        color: $dark-text;
        background-color: $light-background-color;
    }
}
.youtube-embed {
    &.large {
        width: 100%;
        height: 40vh;
    }
    &.medium {
        width: 60%;
        height: 30vh;
    }
    &.small {
        width: 30%;
        height: 20vh;
    }
}


// utils
a {
    text-decoration: none;
    color: inherit;
}

.black-ops-one-regular {
    font-family: "Black Ops One", system-ui;
    font-weight: 400;
    font-style: normal;
}
.btn {
    @include flex-box(center, center, row, 10px);
    padding: 0.6rem 1.6rem;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 700;
    font-size: 1.1rem;
    height: fit-content;

    &.success {
        background-color: green;
    }
    &.danger {
        background-color: red;
        color: $light-text;
    }
    &.warn {
        background-color: yellow;
    }
    &.primary {
        background-color: $primary-color;
        color: $light-text;
    }
    &.secondary {
        background-color: $secondary-color;
    }
    &.disabled {
        background-color: $primary-fade-color;
    }
    &.wallet-btn {
        height: 3rem;
        font-weight: 600;

        svg {
            height: 2rem;
            width: 2rem;
        }

        .disconnected,
        .connected {
            @include flex-box(center, center, row, 0.3rem);

            .address {
                @include flex-box(center, center);
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}
.screen-container {
    @include elastic-width($content-width);
    @include centered-by-margin();
    padding: 1.1%;
}
.full-width {
    width: 100%;
}
.copy-notification {
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 999;
    padding: 0.3rem 1rem;
    background-color: $light-text;
    border-radius: 5rem;
    animation: slideEntryDown 350ms;
}
.claim-container {
    max-width: 550px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    animation: slideDown 250ms;
}

// modal
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 998;
    background-color: rgba(30, 41, 59, 0.868);
    backdrop-filter: blur(10px);
    overflow: clip !important;
    padding: 2%;
}
.modal {
    @include elastic-width(450px);
    min-height: 200px;
    position: relative;
    top: 20%;
    left: 50%;
    // border-bottom-right-radius: 20px;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2rem), calc(100% - 2rem) 100%, 0 100%);
    background-image: url(../images/coin.png);
    background-repeat: no-repeat;
    background-blend-mode: color;
    background-position-y: -50%;

    &.enter {
        transform: translate(-50%, 0%);
        animation: slideDown 450ms alternate;
    }

    &.exit {
        transform: translate(-50%, -300%);
        animation: slideUp 450ms alternate;
    }

    .modal-head {
        @include flex-box(center, flex-end);
        width: 100%;
        padding: 1%;
        background-color: $primary-color;

        svg {
            width: 8%;
            height: 8%;
            cursor: pointer;
        }
    }

    .modal-content {
        padding: 2%;
        width: 100%;
    }

    .content-container {
        width: 100%;

        .instruct {
            padding: 2% 5%;
        }

        .options {
            @include flex-box(center, center);
            width: 100%;
            height: fit-content;
            padding-bottom: 2rem;

            &.disconnect {
                .option {
                    flex: 1;
                    @include flex-box(center, center, column);

                    &:hover {
                        background-color: $primary-color;
                        transform: scale(1.02) !important;
                    }

                    svg {
                        width: 20%;
                        height: 20%;
                    }
                }
            }

            .option {
                background-color: $fade-color;
                clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
                height: 100%;
                padding: 2%;
                border-bottom-right-radius: 20px;
                cursor: pointer;
                transition: transform ease-in-out 250ms;

                &:hover {
                    background-color: $primary-color;
                    transform: rotateZ(10deg) scale(1.02);
                }

                img {
                    height: 3rem;
                }
            }
        }

        .disclaimer {
            margin-bottom: 2rem;

            .container {

                .row {
                    padding: 1%;

                    .tokens {

                        .token-row {
                            @include flex-box(center, space-between);

                            img {
                                height: 1.5rem;
                                width: auto;
                            }

                            &>div {
                                @include flex-box(center, space-between, row, 0.6rem);
                                font-size: 1.1rem;
                                font-weight: 700;
                                color: $gray-color;
                            }
                        }

                        .demarcator {
                            padding: 0.4rem;
                        }
                    }

                    .trade-info {
                        font-weight: 500;

                        .row {
                            @include flex-box(center, space-between);

                            .value {
                                @include flex-box(center, flex-end, row, 0.3rem);
                            }
                        }
                    }
                }
            }
        }
    }
}

.wait-container {
    @include flex-box(center, center);
    height: 100%;
    width: 100%;

    .wait-icon {
        width: 2rem;
        height: 2rem;
        animation: grow 250ms;

        img {
            height: 100%;
            width: auto;
        }
    }
}

// Convert (Swap)
.swap-box {
    @include elastic-width(450px);
    @include centered-by-margin();
    margin-top: 5%;

    .swap-text {
        padding: 4% 0;
        font-weight: 600;
        font-style: italic;
        color: goldenrod;
    }

    .swap {
        background-color: $fade-color;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
        padding: 10% 0;

        .token-data {
            padding: 2%;

            .container {
                clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);

                .row {
                    @include flex-box(center, space-between);
                    padding: 1% 4%;
                    font-weight: 600;

                    &.collapse {
                        padding: 0 4% !important;
                        margin: 0 !important;
                        transform: translate(0, -50%);
                        animation: slideLeft 250ms alternate 250ms;
                    }
                    .portions {
                        @include flex-box(center, space-between, row, 10px);
                        font-size: 12px;

                        .part {
                            &:hover {
                                color: $primary-color;
                            }

                            &.active {
                                color: $primary-color;
                            }
                        }
                    }

                    .token-container {
                        @include flex-box(center, flex-start, row, 10px);
                        font-size: 1.3rem;

                        .icon {
                            height: 1.3rem;
                            width: 1.3rem;

                            img {
                                height: 100%;
                            }
                        }
                    }

                    .value-container {
                        input {
                            width: 100%;
                            font-weight: 600;
                            background-color: transparent;
                            font-size: 2rem;
                            text-align: right;
                            color: inherit;
                        }
                    }
                }
                .row:last-child {
                    font-size: 0.8rem;
                    color: $gray-color;
                }
            }
        }

        .checks {
            width: 100%;

            .check {
                @include flex-box(baseline, flex-start, row, 0.4rem);
                padding: 1% 2%;
                font-size: 0.8rem;
                font-weight: 500;
                color: $gray-color;
            }
        }

        .rates {
            @include flex-box(center, flex-start);
            padding: 2%;
            font-size: 0.8rem;
            font-weight: 600;
            color: $gray-color;
        }

        .form-action {
            width: 100%;
            padding: 4% 2%;

            button {
                width: 100%;
            }
        }

        .swap-stats {
            padding: 3% 2%;
            animation: slideRight 250ms;

            .container {
                @include box-border(all, 1px, solid, $primary-color);

                .row {
                    @include flex-box(center, space-between);
                    padding: 1% 4%;
                    font-weight: 400;
                    font-size: 0.8rem;
                    color: $gray-color;

                    .values {
                        @include flex-box(center, center, row, 10px);
                    }
                }
            }

        }
    }
}

// Count Down
.timer {
    .timer-title {
        width: fit-content;
        height: 1% !important;
        background-color: $primary-color;
        padding: 0.1% 2%;
        position: relative;
        white-space: nowrap;
        z-index: 1;

        &::before {
            content: "";
            background-color: $secondary-color;
            transform: skew(-25deg);
            position: absolute;
            height: 100%;
            width: 80px;
            top: 0;
            left: 90%;
            z-index: -1;
        }

        &::after {
            content: "";
            background-color: $secondary-color;
            transform: skew(-25deg);
            position: absolute;
            height: 100%;
            width: 80px;
            top: 0;
            left: 40%;
            transform: translate(40%, 0);
            z-index: -1;
            animation: shootEffect 1900ms infinite alternate-reverse;
        }
    }

    .timer-counter {
        @include flex-box(center, flex-start);
        margin: 1rem 0;

        .timer-column {
            line-height: 1;
        }
    }
}

// notifications
.notification-container {
    position: fixed;
    top: 0;
    right: 0.6rem;
    padding: 0.5rem 1rem;
    width: 250px;
    z-index: 9999;

    .notification {
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
        padding: 0.6rem;
        display: flex;
        align-items: flex-start;
        gap: 0.3rem;
        animation-duration: 350ms;
        cursor: alias;

        &.hidden {
            opacity: 0;
            transform: translate(200%, 0);
        }

        &.enabled {
            animation-name: slideLeft;
            transform: translate(0, 0);
        }

        &.disabled {
            animation: slideExitRight 350ms alternate;
            transform: translate(200%, 0);
        }

        &.success {
            background-color: #0fa088;
        }

        &.error {
            background-color: #f05864;
        }

        &.warning {
            background-color: #fac34d;
            color: #000;
        }

        .absolute-right {
            position: absolute;
            top: 0.3rem;
            right: 0.3rem;
            z-index: 1;
        }

        .status-icon {
            svg {
                height: 1.6rem;
                width: 1.6rem;
            }
        }

        .notification-message {
            font-size: 0.8rem;
            overflow-x: auto;
        }
    }
}