@import "./variables";

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}
@mixin box-shadow($h-offset, $v-offset, $blur, $spread, $color: $secondary-color, $inset: "") {
    @if ($inset == "") {
        box-shadow: $h-offset $v-offset $blur $spread $color;
    } @else {
        box-shadow: inset $h-offset $v-offset $blur $spread $color;
    }
}
@mixin box-border($position: all, $width: 1px, $style: solid, $color: $primary-color) {
    @if ($position == top) {border-top: $width $style $color;}
    @else if ($position == bottom) {border-bottom: $width $style $color;}
    @else if ($position == left) {border-left: $width $style $color;}
    @else if ($position == right) {border-right: $width $style $color;}
    @else {border: $width $style $color;}
}
@mixin box-shadow-2x($definition1, $definition2) {
    box-shadow: $definition1, $definition2;
}
@mixin elastic-width($limit) {
    width: 100%;
    max-width: $limit;
}
@mixin grid-box($columns, $gap, $rows) {
    display: grid;
    grid-template-columns: $columns;
    grid-template-rows: $rows;
    gap: $gap;
}
@mixin flex-box($align: center, $justify: center, $direction: row, $gap: 1%) {
    display: flex;
    flex-direction: $direction;
    align-items: $align;
    justify-content: $justify;
    gap: $gap;
}
@mixin centered-by-margin($x: auto, $y: 0) {
    margin: $y $x;
}