
.padded-box-xxl {
    padding: 5%;
}
.padded-box-xl {
    padding: 4%;
}
.padded-box-l {
    padding: 3%;
}
.padded-box-m {
    padding: 2%;
}
.padded-box-s {
    padding: 1%;
}
.padded-box-y-xxl {
    padding: 5% 0;
}
.padded-box-y-xl {
    padding: 4% 0;
}
.padded-box-y-l {
    padding: 3% 0;
}
.padded-box-y-m {
    padding: 2% 0;
}
.padded-box-y-s {
    padding: 1% 0;
}
.padded-box-x-xxl {
    padding: 0 5%;
}
.padded-box-x-xl {
    padding: 0 4%;
}
.padded-box-x-l {
    padding: 0 3%;
}
.padded-box-x-m {
    padding: 0 2%;
}
.padded-box-x-s {
    padding: 0 1%;
}
