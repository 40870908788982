
@import './mixins';
@import './variables';

body {

    &.light {
        background-color: $light-background-color;
        color: $dark-text;

        header {
            background-color: $light-background-color;
        }
        header.desktop {
            @include box-border(bottom, 1px, solid, $dark-background-color);
        }
        header.mobile {
            nav {
                .header-icon {
                    background-color: $light-background-color;
                }
                .menu>.menu-wrapper {
                    background-color: $light-background-color;
                }
            }
        }
        .page-banner {
            @include box-shadow(0, 10px, 50px, -15px);
            background-color: rgba(198, 216, 205, 0.9);
        }
        .modal {
            background-color: $light-background-color;
        }
        .wallet-btn {
            svg {
                fill: $dark-text;
                stroke: $dark-text;
            }
        }
        .tasks {
            background-color: rgba(198, 216, 205, 0.9);
        }
        .token-data {
            .container {
                background-color: $light-background-color;
            }
        }
    }
    &.dark {
        background-color: $dark-background-color;
        color: $light-text;

        header {
            background-color: $dark-background-color;
        }
        header.desktop {
            @include box-border(bottom, 1px, solid, $light-background-color);
        }
        header.mobile {
            nav {
                .header-icon {
                    background-color: $dark-background-color;
                }
                .menu>.menu-wrapper {
                    background-color: $dark-background-color;
                }
            }
        }
        .page-banner {
            @include box-shadow(0, 10px, 50px, -15px);
            background-color: rgb(0, 0, 0, 0.9);
        }
        .modal {
            background-color: $dark-background-color;
        }
        .tasks {
            background-color: $fade-color;
        }
        .token-data {
            .container {
                background-color: $dark-background-color;
            }
            .row:first-child {
                color: $gray-color;
            }
        }
    }
}

.wallet-btn {
    svg {
        fill: $light-text !important;
        stroke: $light-text !important;
    }
}
.primary-color {
    color: $primary-color;
}
.primary-fade-color {
    color: $primary-fade-color;
}
.secondary-color {
    color: $secondary-color;
}
.light-fade-color {
    color: $light-fade-color;
}
.fade-color {
    color: $fade-color;
}
.goldenrod {
    color: goldenrod
}
.warning-color {
    color: #c5ac1c;
}
.success-color {
    color: green;
}
.error-color {
    color: red;
}
.gray-color {
    color: $gray-color;
}