@import './mixins';
@import './variables';
@import './keyframes';

@media screen and (max-width: 1080px) {
    
}
@media screen and (max-width: 800px) {
    .screen-container {
        .container {
            @include flex-box(baseline, center, column);
            &>div:nth-child(2) {
                order: 3;
                flex-direction: column;
                
                .column {
                    padding: 1rem;
                }
            }
            &>div:nth-child(3) {
                order: 2;

                .timer-counter {
                    width: 100%;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .how-to-title {
      font-size: 2rem;
    }
  
    .step-title {
      font-size: 1.6rem;
    }
  
    .step-description {
      font-size: 1rem;
    }
}