@import url("https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@keyframes grow {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes absoluteGrow {
  0% {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideExitLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideExitRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}
@keyframes slideUp {
  0% {
    transform: translate(-50%, 0%);
  }
  100% {
    transform: translate(-50%, -300%);
  }
}
@keyframes slideDown {
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 0%);
  }
}
@keyframes slideEntryDown {
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
@keyframes shootEffect {
  0% {
    transform: translate(0, 0) skew(-25deg);
  }
  45% {
    transform: translate(-110%, 0) skew(-25deg);
  }
  60% {
    transform: translate(110%, 0) skew(-25deg);
  }
  80% {
    transform: translate(70%, 0) skew(-25deg);
  }
  100% {
    transform: translate(100%, 0) skew(-25deg);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.5);
  }
  66% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: "Fira Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  transition: all 250ms;
  border: none;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: 1px;
}

body {
  width: 100vw;
  min-height: 100vh;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
}

header {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 998;
}
header.header nav {
  width: 100%;
  max-width: 1248px;
  margin: 0 auto;
  padding: inherit;
}
header.desktop {
  padding: 5px 1.1%;
}
header.desktop nav {
  overflow: clip;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
header.desktop nav .menu-toggle {
  display: none;
}
header.desktop nav .menu .menu-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  width: -moz-max-content;
  width: max-content;
}
header.desktop nav .menu .menu-wrapper > div:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px;
}
header.desktop nav .menu .menu-wrapper > div:last-child {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
header.desktop nav .menu .menu-wrapper .menu-item {
  padding: 10px 20px;
}
header.desktop nav .menu .menu-wrapper .menu-item.active {
  box-shadow: inset 0px 10px 20px 1px #586871;
}
header.mobile {
  border-bottom: 2px solid #1e293b;
  z-index: 999;
}
header.mobile nav {
  position: relative;
}
header.mobile nav .header-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1%;
  width: 100%;
  padding: 10px;
  height: 4rem;
  position: relative;
}
header.mobile nav .header-icon img {
  height: 1.6rem;
}
header.mobile nav .header-icon .menu-toggle {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  padding: 1%;
}
header.mobile nav .header-icon .menu-toggle svg {
  height: 2rem;
  width: auto;
  cursor: pointer;
  fill: #009393;
}
header.mobile nav .menu {
  width: 100%;
  height: calc(100vh - 2rem - 2px);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
}
header.mobile nav .menu.hidden {
  display: none;
}
header.mobile nav .menu.transient .menu-wrapper {
  animation: slideExitLeft 250ms alternate;
  transform: translateX(-100%);
}
header.mobile nav .menu.visible .menu-wrapper {
  animation: slideRight 250ms alternate;
  transform: translateX(0);
}
header.mobile nav .menu .menu-wrapper {
  width: 100%;
  max-width: 500px;
  height: 100%;
  position: relative;
}
header.mobile nav .menu .menu-wrapper .menu-item {
  margin: 0.3rem;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
}
header.mobile nav .menu .menu-wrapper .selector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1%;
  padding: 2%;
  background-color: #009393;
}
header.mobile nav .menu .menu-wrapper .selector svg {
  height: 2rem;
  width: 2rem;
}
header.mobile nav .menu .menu-wrapper .actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1%;
  margin-top: 2rem;
}

main {
  width: 100%;
  max-width: 1248px;
  margin: 0 auto;
  padding: 1.1%;
  padding-bottom: 5rem;
}
main .map {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1%;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.8rem;
  margin: 1rem 0;
  padding: 0.3rem 1rem;
}
main .map .pos {
  padding: 0 4%;
  white-space: nowrap;
  font-style: italic;
}
main .map svg {
  width: 1rem;
  height: 1rem;
  fill: #009393;
}
main .container.guide-menu .row.top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1%;
  height: calc(2rem + 2%);
  padding-left: 4.4rem;
  width: -moz-fit-content;
  width: fit-content;
}
main .container.guide-menu .row.top .column {
  flex: 1;
  height: 1;
  white-space: nowrap;
  padding: 2% 4%;
  padding-right: calc(4% + 2rem);
  background-color: #79c0c0;
  border-radius: 0 2rem 0 0;
  position: relative;
  color: #666;
  font-weight: 700;
  font-size: 0.8rem;
}
main .container.guide-menu .row.top .column::before {
  content: "";
  height: 100%;
  width: 1rem;
  position: absolute;
  background-color: #79c0c0;
  top: 0;
  left: -1rem;
  z-index: -1;
}
main .container.guide-menu .row.top .column.active {
  background-color: #009393;
  color: #fff;
}
main .container.guide-menu .row.top .column.active::before {
  content: "";
  background-color: #009393;
  z-index: 1;
}
main .container.guide-menu .row.bottom {
  width: 100%;
  height: 2rem;
  background-color: #009393;
  border-radius: 2rem 2rem 0 0;
  z-index: -2;
}
main .guide-content .how-to-container {
  width: 100%;
  max-width: 1024px;
  padding: 20px;
  margin: auto;
  font-family: "Arial", sans-serif;
}
main .guide-content .how-to-title {
  font-size: 2.5rem;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}
main .guide-content .how-to-intro {
  font-size: 1.2rem;
  text-align: center;
  color: #666;
  margin-bottom: 40px;
}
main .guide-content .steps-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: clip;
  padding: 1rem;
}
main .guide-content .step-card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: translateX(0);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: slideRight 250ms alternate;
}
main .guide-content .step-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
main .guide-content .step-title {
  font-size: 1.3rem;
  margin-bottom: 10px;
  color: #009393;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 2px solid #009393;
}
main .guide-content .step-description {
  font-size: 1.1rem;
  color: #555;
}

.theme-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 7rem;
}

.page-banner {
  width: 100%;
  min-height: 30vh;
  padding-bottom: 4rem;
}

.table-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1%;
  width: 100%;
}
.table-stats div {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1%;
}

.task-container .tasks {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2rem), calc(100% - 2rem) 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2rem), calc(100% - 2rem) 100%, 0 100%);
  padding: 2rem 1rem;
}
.task-container .tasks .task {
  border: 2px solid #009393;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  position: relative;
}
.task-container .tasks .task .action-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1%;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 2rem;
  background-color: #586871;
  cursor: pointer;
}
.task-container .tasks .task .action-box:hover {
  background-color: #009393;
}
.task-container .tasks .task .text-box {
  padding: 2%;
}
.task-container .tasks .task .text-box .rate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.3rem;
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: #666;
  color: #fff;
  padding: 0.3rem;
  font-size: 0.6rem;
  font-weight: 800;
  font-style: italic;
}
.task-container .tasks .task .text-box .rate.earned {
  background-color: green !important;
}
.task-container .tasks .task .text-box .rate.earned svg {
  fill: goldenrod;
}
.task-container .tasks .task .post {
  width: calc(100% - 2rem);
}
.task-container .text-box {
  position: relative;
}
.task-container .post {
  padding: 1rem;
  color: #000;
  background-color: #c5c9d1;
}

.youtube-embed.large {
  width: 100%;
  height: 40vh;
}
.youtube-embed.medium {
  width: 60%;
  height: 30vh;
}
.youtube-embed.small {
  width: 30%;
  height: 20vh;
}

a {
  text-decoration: none;
  color: inherit;
}

.black-ops-one-regular {
  font-family: "Black Ops One", system-ui;
  font-weight: 400;
  font-style: normal;
}

.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0.6rem 1.6rem;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.1rem;
  height: -moz-fit-content;
  height: fit-content;
}
.btn.success {
  background-color: green;
}
.btn.danger {
  background-color: red;
  color: #fff;
}
.btn.warn {
  background-color: yellow;
}
.btn.primary {
  background-color: #009393;
  color: #fff;
}
.btn.secondary {
  background-color: #586871;
}
.btn.disabled {
  background-color: #79c0c0;
}
.btn.wallet-btn {
  height: 3rem;
  font-weight: 600;
}
.btn.wallet-btn svg {
  height: 2rem;
  width: 2rem;
}
.btn.wallet-btn .disconnected,
.btn.wallet-btn .connected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}
.btn.wallet-btn .disconnected .address,
.btn.wallet-btn .connected .address {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1%;
}

.cursor-pointer {
  cursor: pointer;
}

.screen-container {
  width: 100%;
  max-width: 1248px;
  margin: 0 auto;
  padding: 1.1%;
}

.full-width {
  width: 100%;
}

.copy-notification {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
  padding: 0.3rem 1rem;
  background-color: #fff;
  border-radius: 5rem;
  animation: slideEntryDown 350ms;
}

.claim-container {
  max-width: 550px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  animation: slideDown 250ms;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  background-color: rgba(30, 41, 59, 0.868);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  overflow: clip !important;
  padding: 2%;
}

.modal {
  width: 100%;
  max-width: 450px;
  min-height: 200px;
  position: relative;
  top: 20%;
  left: 50%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2rem), calc(100% - 2rem) 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2rem), calc(100% - 2rem) 100%, 0 100%);
  background-image: url(../images/coin.png);
  background-repeat: no-repeat;
  background-blend-mode: color;
  background-position-y: -50%;
}
.modal.enter {
  transform: translate(-50%, 0%);
  animation: slideDown 450ms alternate;
}
.modal.exit {
  transform: translate(-50%, -300%);
  animation: slideUp 450ms alternate;
}
.modal .modal-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1%;
  width: 100%;
  padding: 1%;
  background-color: #009393;
}
.modal .modal-head svg {
  width: 8%;
  height: 8%;
  cursor: pointer;
}
.modal .modal-content {
  padding: 2%;
  width: 100%;
}
.modal .content-container {
  width: 100%;
}
.modal .content-container .instruct {
  padding: 2% 5%;
}
.modal .content-container .options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1%;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding-bottom: 2rem;
}
.modal .content-container .options.disconnect .option {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1%;
}
.modal .content-container .options.disconnect .option:hover {
  background-color: #009393;
  transform: scale(1.02) !important;
}
.modal .content-container .options.disconnect .option svg {
  width: 20%;
  height: 20%;
}
.modal .content-container .options .option {
  background-color: #1e293b;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
  height: 100%;
  padding: 2%;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  transition: transform ease-in-out 250ms;
}
.modal .content-container .options .option:hover {
  background-color: #009393;
  transform: rotateZ(10deg) scale(1.02);
}
.modal .content-container .options .option img {
  height: 3rem;
}
.modal .content-container .disclaimer {
  margin-bottom: 2rem;
}
.modal .content-container .disclaimer .container .row {
  padding: 1%;
}
.modal .content-container .disclaimer .container .row .tokens .token-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1%;
}
.modal .content-container .disclaimer .container .row .tokens .token-row img {
  height: 1.5rem;
  width: auto;
}
.modal .content-container .disclaimer .container .row .tokens .token-row > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.6rem;
  font-size: 1.1rem;
  font-weight: 700;
  color: #666;
}
.modal .content-container .disclaimer .container .row .tokens .demarcator {
  padding: 0.4rem;
}
.modal .content-container .disclaimer .container .row .trade-info {
  font-weight: 500;
}
.modal .content-container .disclaimer .container .row .trade-info .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1%;
}
.modal .content-container .disclaimer .container .row .trade-info .row .value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.3rem;
}

.wait-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1%;
  height: 100%;
  width: 100%;
}
.wait-container .wait-icon {
  width: 2rem;
  height: 2rem;
  animation: grow 250ms;
}
.wait-container .wait-icon img {
  height: 100%;
  width: auto;
}

.swap-box {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  margin-top: 5%;
}
.swap-box .swap-text {
  padding: 4% 0;
  font-weight: 600;
  font-style: italic;
  color: goldenrod;
}
.swap-box .swap {
  background-color: #1e293b;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
  padding: 10% 0;
}
.swap-box .swap .token-data {
  padding: 2%;
}
.swap-box .swap .token-data .container {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
}
.swap-box .swap .token-data .container .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1%;
  padding: 1% 4%;
  font-weight: 600;
}
.swap-box .swap .token-data .container .row.collapse {
  padding: 0 4% !important;
  margin: 0 !important;
  transform: translate(0, -50%);
  animation: slideLeft 250ms alternate 250ms;
}
.swap-box .swap .token-data .container .row .portions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 12px;
}
.swap-box .swap .token-data .container .row .portions .part:hover {
  color: #009393;
}
.swap-box .swap .token-data .container .row .portions .part.active {
  color: #009393;
}
.swap-box .swap .token-data .container .row .token-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 1.3rem;
}
.swap-box .swap .token-data .container .row .token-container .icon {
  height: 1.3rem;
  width: 1.3rem;
}
.swap-box .swap .token-data .container .row .token-container .icon img {
  height: 100%;
}
.swap-box .swap .token-data .container .row .value-container input {
  width: 100%;
  font-weight: 600;
  background-color: transparent;
  font-size: 2rem;
  text-align: right;
  color: inherit;
}
.swap-box .swap .token-data .container .row:last-child {
  font-size: 0.8rem;
  color: #666;
}
.swap-box .swap .checks {
  width: 100%;
}
.swap-box .swap .checks .check {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  gap: 0.4rem;
  padding: 1% 2%;
  font-size: 0.8rem;
  font-weight: 500;
  color: #666;
}
.swap-box .swap .rates {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1%;
  padding: 2%;
  font-size: 0.8rem;
  font-weight: 600;
  color: #666;
}
.swap-box .swap .form-action {
  width: 100%;
  padding: 4% 2%;
}
.swap-box .swap .form-action button {
  width: 100%;
}
.swap-box .swap .swap-stats {
  padding: 3% 2%;
  animation: slideRight 250ms;
}
.swap-box .swap .swap-stats .container {
  border: 1px solid #009393;
}
.swap-box .swap .swap-stats .container .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1%;
  padding: 1% 4%;
  font-weight: 400;
  font-size: 0.8rem;
  color: #666;
}
.swap-box .swap .swap-stats .container .row .values {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.timer .timer-title {
  width: -moz-fit-content;
  width: fit-content;
  height: 1% !important;
  background-color: #009393;
  padding: 0.1% 2%;
  position: relative;
  white-space: nowrap;
  z-index: 1;
}
.timer .timer-title::before {
  content: "";
  background-color: #586871;
  transform: skew(-25deg);
  position: absolute;
  height: 100%;
  width: 80px;
  top: 0;
  left: 90%;
  z-index: -1;
}
.timer .timer-title::after {
  content: "";
  background-color: #586871;
  transform: skew(-25deg);
  position: absolute;
  height: 100%;
  width: 80px;
  top: 0;
  left: 40%;
  transform: translate(40%, 0);
  z-index: -1;
  animation: shootEffect 1900ms infinite alternate-reverse;
}
.timer .timer-counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1%;
  margin: 1rem 0;
}
.timer .timer-counter .timer-column {
  line-height: 1;
}

.notification-container {
  position: fixed;
  top: 0;
  right: 0.6rem;
  padding: 0.5rem 1rem;
  width: 250px;
  z-index: 9999;
}
.notification-container .notification {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1rem), calc(100% - 1rem) 100%, 0 100%);
  padding: 0.6rem;
  display: flex;
  align-items: flex-start;
  gap: 0.3rem;
  animation-duration: 350ms;
  cursor: alias;
}
.notification-container .notification.hidden {
  opacity: 0;
  transform: translate(200%, 0);
}
.notification-container .notification.enabled {
  animation-name: slideLeft;
  transform: translate(0, 0);
}
.notification-container .notification.disabled {
  animation: slideExitRight 350ms alternate;
  transform: translate(200%, 0);
}
.notification-container .notification.success {
  background-color: #0fa088;
}
.notification-container .notification.error {
  background-color: #f05864;
}
.notification-container .notification.warning {
  background-color: #fac34d;
  color: #000;
}
.notification-container .notification .absolute-right {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  z-index: 1;
}
.notification-container .notification .status-icon svg {
  height: 1.6rem;
  width: 1.6rem;
}
.notification-container .notification .notification-message {
  font-size: 0.8rem;
  overflow-x: auto;
}/*# sourceMappingURL=main.css.map */