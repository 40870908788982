body.light {
  background-color: #c5c9d1;
  color: #000;
}
body.light header {
  background-color: #c5c9d1;
}
body.light header.desktop {
  border-bottom: 1px solid #121213;
}
body.light header.mobile nav .header-icon {
  background-color: #c5c9d1;
}
body.light header.mobile nav .menu > .menu-wrapper {
  background-color: #c5c9d1;
}
body.light .page-banner {
  box-shadow: 0 10px 50px -15px #586871;
  background-color: rgba(198, 216, 205, 0.9);
}
body.light .modal {
  background-color: #c5c9d1;
}
body.light .wallet-btn svg {
  fill: #000;
  stroke: #000;
}
body.light .tasks {
  background-color: rgba(198, 216, 205, 0.9);
}
body.light .token-data .container {
  background-color: #c5c9d1;
}
body.dark {
  background-color: #121213;
  color: #fff;
}
body.dark header {
  background-color: #121213;
}
body.dark header.desktop {
  border-bottom: 1px solid #c5c9d1;
}
body.dark header.mobile nav .header-icon {
  background-color: #121213;
}
body.dark header.mobile nav .menu > .menu-wrapper {
  background-color: #121213;
}
body.dark .page-banner {
  box-shadow: 0 10px 50px -15px #586871;
  background-color: rgba(0, 0, 0, 0.9);
}
body.dark .modal {
  background-color: #121213;
}
body.dark .tasks {
  background-color: #1e293b;
}
body.dark .token-data .container {
  background-color: #121213;
}
body.dark .token-data .row:first-child {
  color: #666;
}

.wallet-btn svg {
  fill: #fff !important;
  stroke: #fff !important;
}

.primary-color {
  color: #009393;
}

.primary-fade-color {
  color: #79c0c0;
}

.secondary-color {
  color: #586871;
}

.light-fade-color {
  color: #3d4c63;
}

.fade-color {
  color: #1e293b;
}

.goldenrod {
  color: goldenrod;
}

.warning-color {
  color: #c5ac1c;
}

.success-color {
  color: green;
}

.error-color {
  color: red;
}

.gray-color {
  color: #666;
}/*# sourceMappingURL=colors.css.map */