@keyframes grow {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes absoluteGrow {
  0% {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slideLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideExitLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideExitRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100vw);
  }
}
@keyframes slideUp {
  0% {
    transform: translate(-50%, 0%);
  }
  100% {
    transform: translate(-50%, -300%);
  }
}
@keyframes slideDown {
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 0%);
  }
}
@keyframes slideEntryDown {
  0% {
    transform: translate(-50%, -100%);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
@keyframes shootEffect {
  0% {
    transform: translate(0, 0) skew(-25deg);
  }
  45% {
    transform: translate(-110%, 0) skew(-25deg);
  }
  60% {
    transform: translate(110%, 0) skew(-25deg);
  }
  80% {
    transform: translate(70%, 0) skew(-25deg);
  }
  100% {
    transform: translate(100%, 0) skew(-25deg);
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.5);
  }
  66% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.animate-grow {
  animation: grow 350ms;
}

.animate-slide-left {
  position: relative;
  animation: slideLeft 350ms forwards;
}

.animate-slide-right {
  position: relative;
  animation: slideRight 350ms forwards;
}

.animate-bounce {
  animation: bounce 5s alternate infinite;
}/*# sourceMappingURL=animations.css.map */