@import './mixins';
@import './variables';
@import './keyframes';

.animate-grow {
    animation: grow 350ms;
}
.animate-slide-left {
    position: relative;
    animation: slideLeft 350ms forwards;
}
.animate-float-slide-left {}
.animate-slide-right {
    position: relative;
    animation: slideRight 350ms forwards;
}
.animate-float-slide-right {}
.animate-bounce {
    animation: bounce 5s alternate infinite;
} 