

$light-background-color: #c5c9d1;
$dark-background-color: #121213;
$primary-color: #009393;
$primary-fade-color: #79c0c0;
$secondary-color: #586871;
$light-text: #fff;
$dark-text: #000;
$fade-color: #1e293b;
$light-fade-color: #3d4c63;
$content-width: 1248px;
$gray-color: #666;